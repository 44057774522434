import { useRouter } from 'next/router';
import { memo } from 'react';
import Image from 'next/image';
import { Typography, Box } from '@mui/material';

import { AppRoutes } from '@utils/appRoutes';
import CuminButton from '@components/Button';

import { GiftSectionContent } from '../constant';
import useStyles from './useStyles';
import useAuth from '@utils/useAuth';

const GiftSection = () => {
  const classes = useStyles();
  const router = useRouter();

  const { detectedCountryCode } = useAuth();

  const onHandleClick = (routes: AppRoutes | string) => {
    if (routes === AppRoutes.GIFT) {
      router.push(routes);
    } else {
      router.push(`${detectedCountryCode}${routes}`);
    }
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h1" color="garamMasala.main" align="center">
        Give the Gift of Food
      </Typography>
      <Typography
        variant="body2"
        color="garamMasala.main"
        align="center"
        className={classes.description}
      >
        There are few things that bring joy the way a plate of delicious food
        can. This holiday season, we can help you bring that joy to a special
        person in your life!
      </Typography>
      <Box className={classes.contentWrapper}>
        {GiftSectionContent.map((item) => (
          <Box key={item.id} className={classes.content}>
            <Box
              className={classes.imgWrapper}
              width={`${item.width}px`}
              height={`${item.height}px`}
            >
              <Image
                src={item.img}
                alt={item.alt}
                layout="fill"
                objectFit="contain"
              />
            </Box>
            <CuminButton
              color="cinnamon"
              variant="contained"
              textColor="whitePepper.main"
              width="220px"
              height="48"
              borderRadius="24"
              className={classes.cuminBtn}
              onClick={() => onHandleClick(item.routes)}
            >
              {item?.btnText}
            </CuminButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default memo(GiftSection);
