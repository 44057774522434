import Image from 'next/image';
import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import LoginIcon from '@mui/icons-material/Login';

import MenuIcon from '@mui/icons-material/Menu';
import theme from 'src/style-system/theme';
import {
  isABTestToFiredVar,
  promotionalUpdatesVar,
  selectAddressIdVar,
  subscriptionIdStateVar,
} from 'store/utils';
import { updateShippingFormInput } from 'store/shippingFormInput';
import { promoCodeDetailsVar, updatePromoCode } from 'store/promoCode';
import { removeCartCount, removePantryCartCount } from 'store/cartItems';
import TCCLogo from 'svgComponents/TccLogo';
import BurgerMenuIcon from 'svgComponents/BurgerMenuIcon';
import CrossSign from '@assets/images/svg/CrossSign.svg';
import { initialValues } from '@utils/validateShippingForm';
import cuminClubWithIcon from '@public/assets/png/cuminClubWithIcon.png';
import TCCLogoNew from '@public/assets/svg/TCCLogoNew.svg';
import Button from '@components/Button';
import { baseHeaderList, headerList, newHeaderList } from '@utils/data';
import useAuth from '@utils/useAuth';
import { AppRoutes } from '@utils/appRoutes';
import ArrowTooltips from '@components/Tooltip';

import { useStyles } from './useStyles';

interface IHomeHeader {
  countryCode: string;
}

const HomeHeader = ({ countryCode }: IHomeHeader) => {
  const [open, setOpen] = useState<boolean>(false);
  const router = useRouter();
  const classes = useStyles();

  const { authState, setAuthState } = useAuth();
  const isAdmin = localStorage.getItem('isAdmin'); // for admin panel
  const isAccountActivated = isAdmin || authState?.user?.isAccountActivated;

  const handleClick = () => {
    router.push(`/${countryCode}${AppRoutes.ORDER_STEP_TWO}`);
  };

  const handleLogout = () => {
    router.push(AppRoutes.HOME_ROUTE);
    localStorage.removeItem('tcc-jwt-token');
    setAuthState({});

    // reset store
    updateShippingFormInput(initialValues);
    updatePromoCode('');
    selectAddressIdVar(null);
    promotionalUpdatesVar(false);
    removeCartCount();
    removePantryCartCount();
    subscriptionIdStateVar('');
    promoCodeDetailsVar(null);
    localStorage.removeItem('planAndFreq');
    localStorage.removeItem('zipCode');
    localStorage.removeItem('subscription-id');
    localStorage.removeItem('email');
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className={classes.mainContainer}>
        <Box
          className={classes.container}
          sx={{
            [theme.breakpoints.down(1040)]: {
              flex: 1,
            },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              height: '36px',
              width: '156px',
              cursor: 'pointer',
            }}
            onClick={() => router.push('/')}
          >
            <TCCLogoNew />
          </Box>
          <Box className={classes.linkItems}>
            {newHeaderList.map((item) => (
              <Link href={item.link} key={item.id}>
                <Typography
                  variant="buttonSmall"
                  color="garamMasala.main"
                  sx={{ cursor: 'pointer' }}
                >
                  {item.value}
                </Typography>
              </Link>
            ))}
          </Box>
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          sx={{
            ...(open && { display: 'none' }),
            marginRight: '0',
            [theme.breakpoints.up(1040)]: {
              display: 'none',
            },
          }}
        >
          <MenuIcon fontSize="large" sx={{ color: '#F0EAE3' }} />
        </IconButton>
        <Box className={classes.container}>
          {!isAccountActivated ? (
            <Box
              sx={{
                [theme.breakpoints.down(1040)]: {
                  display: 'none !important',
                },
              }}
            >
              <Button
                color="cinnamon"
                variant="contained"
                textColor="whitePepper.main"
                borderRadius="60"
                fontVariant="buttonSmall"
                height="45"
                width="min-content"
                border={`2px solid ${theme.palette.garamMasala.main}`}
                onClick={() => router.push(`/${AppRoutes.LOGIN}`)}
              >
                Login
              </Button>
            </Box>
          ) : null}
          <Box
            sx={{
              [theme.breakpoints.down(1040)]: {
                display: 'none !important',
              },
            }}
          >
            <Button
              color="turmeric"
              textColor="dark3.main"
              variant="contained"
              borderRadius="60"
              fontVariant="buttonSmall"
              onClick={handleClick}
            >
              Build Your Box
            </Button>
          </Box>
          {
            isAccountActivated ? (
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '24px',
                  [theme.breakpoints.down(1040)]: {
                    display: 'none !important',
                  },
                }}
              >
                <PersonIcon
                  fontSize="large"
                  sx={{ color: 'garamMasala.main', cursor: 'pointer' }}
                  onClick={() => router.push(`/${AppRoutes.ORDERS}`)}
                />
                <ArrowTooltips title="Logout">
                  <LogoutIcon
                    fontSize="large"
                    sx={{ color: 'garamMasala.main', cursor: 'pointer' }}
                    onClick={handleLogout}
                  />
                </ArrowTooltips>
              </Box>
            ) : null
            /* <Box
                sx={{
                  [theme.breakpoints.down(1040)]: {
                    display: 'none !important',
                  },
                }}
              >
                <Button
                  color="cinnamon"
                  variant="contained"
                  textColor="whitePepper.main"
                  borderRadius="60"
                  fontVariant="buttonSmall"
                  height="45"
                  width="min-content"
                  border={`2px solid ${theme.palette.garamMasala.main}`}
                  onClick={() => router.push(`/${AppRoutes.LOGIN}`)}
                >
                  Login
                </Button>
              </Box>
              <Box
                sx={{
                  [theme.breakpoints.up(1040)]: {
                    display: 'none !important',
                  },
                }}
              >
                <PersonIcon
                  fontSize="large"
                  sx={{ color: 'dark3.main', cursor: 'pointer' }}
                  onClick={() => router.push(`/${AppRoutes.LOGIN}`)}
                />
              </Box> */
          }
        </Box>
      </Box>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: '100vw',
            height: '100vh',
            backgroundColor: 'garamMasala.main',
            padding: '32px 35px',
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
        variant="persistent"
        anchor="top"
        open={open}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Link href={AppRoutes.HOME_ROUTE}>
            <a>
              <TCCLogo />
            </a>
          </Link>

          <IconButton onClick={handleDrawerClose}>
            <CrossSign />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid container sx={{ rowGap: { xs: '25px', md: '65px' } }}>
            {headerList
              .filter(({ id }) => {
                if (id === 3) {
                  if (isAccountActivated) {
                    return false;
                  }
                  return true;
                }
                if (id === 2) {
                  if (!isAccountActivated) {
                    return false;
                  }
                  return true;
                }
                return true;
              })
              .map((item) => (
                <Grid item xs={12} md={6} key={item.id}>
                  {item.link === AppRoutes.ORDER_STEP_ONE ? (
                    <Typography
                      variant="h1"
                      align="center"
                      color="turmeric.main"
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClick}
                    >
                      {item.value}
                    </Typography>
                  ) : (
                    <Link
                      href={
                        item.link === AppRoutes.ORDER_STEP_ONE
                          ? `/${countryCode}${AppRoutes.ORDER_STEP_ONE}`
                          : item.link
                      }
                    >
                      <Typography
                        variant="h1"
                        align="center"
                        color="turmeric.main"
                        sx={{ cursor: 'pointer' }}
                      >
                        {item.value}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              ))}
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
              justifyContent: 'center',
              gap: '100px',
            },
          }}
        >
          {/*Before changes baseHeaderList make sure to check the logic logout  */}
          {baseHeaderList
            .filter(({ id }) =>
              !isAccountActivated && id === 5 ? false : true
            )
            .map((item) => (
              <Box
                key={item.id}
                sx={{
                  [theme.breakpoints.up('md')]: {
                    cursor: 'pointer',
                  },
                }}
              >
                {item.link ? (
                  <Link href={item.link}>
                    <Typography
                      variant="body1"
                      align="center"
                      color="cinnamon.main"
                    >
                      {item.value}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    color="cinnamon.main"
                    onClick={() => (item.id === 5 ? handleLogout() : {})}
                  >
                    {item.value}
                  </Typography>
                )}
              </Box>
            ))}
        </Box>
      </Drawer>
    </>
  );
};

export default HomeHeader;
